import 'bootstrap'
import './sass/main.scss'

const header = document.querySelector('.header')

const handleHeaderClassList = () => {
  const headerBgIsFilled = header.classList.contains('header--scrolled')
  if (window.scrollY > 0 && !headerBgIsFilled) header.classList.add('header--scrolled')
  else if (window.scrollY === 0 && headerBgIsFilled) header.classList.remove('header--scrolled')
}

document.addEventListener('scroll', handleHeaderClassList)

// // // // //

const form = document.querySelector('.contact-form')

form.addEventListener('submit', e => {
  e.preventDefault()

  const payload = {}
  const fields = form.querySelectorAll('.contact-form__input')

  fields.forEach(field => payload[field.name] = field.name)

  fetch('/mail.php', payload)
})
